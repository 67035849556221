<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Credenciales</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field v-model="correoIngresado" label="Correo electrónico*" outlined maxlength="150" counter
          class="rounded-lg" color="blueConaipd" :error-messages="correoErrors" required email
          :rules="[rules.email]"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field v-model="contraseña" label="Contraseña*" outlined class="rounded-lg" color="blueConaipd"
          :error-messages="errorContraseña" maxlength="16" counter :type="verContraseña ? 'text' : 'password'"
          :append-icon="verContraseña ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.contraseña]"
          @click:append="verContraseña = !verContraseña">
        </v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field v-model="confirmarContraseña" label="Confirmar contraseña*" outlined class="rounded-lg"
          color="blueConaipd" :error-messages="errorConfirmarContraseña" maxlength="16" counter
          :type="verConfirmarContraseña ? 'text' : 'password'"
          :append-icon="verConfirmarContraseña ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.contraseña]"
          @click:append="verConfirmarContraseña = !verConfirmarContraseña">
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { email, required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Paso6",
  props: {
    /* ArrayPersona: {
      type: Array,
      default: () => {
      }
    } */
  },
  data() {
    return {
      correoIngresado: null,
      contraseña: null,
      confirmarContraseña: null,

      verContraseña: false,
      verConfirmarContraseña: false,

      errorCorreo: [],
      errorContraseña: [],
      errorConfirmarContraseña: [],

      regex_email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      regex_contraseña: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/,
      rules: {
        required: value => !!value || 'Favor ingresar el frente del documento.',
        contraseña: value => {
          const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/
          return pattern.test(value) || 'La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El correo no cuenta con un formato valido'
        }
      },
    };
  },
  validations: {
    correoIngresado: { required, email },
    contraseña: { required },
    confirmarContraseña: { required }
  },
  methods: {
    async validar_paso() {
      this.$v.$touch();
          if (this.$v.$invalid) return;
      if (this.contraseña != null && this.contraseña != "" && this.confirmarContraseña != null && this.confirmarContraseña != "") {
        if (this.contraseña == this.confirmarContraseña) {
          this.$v.$touch();
          if (!this.$v.$invalid) return true;
        }
        else{
          this.temporalAlert({
          show: true,
          message: "Las contraseñas no son iguales",
          type: "error",
        });
        }
      }
      else {
        return false
      }
    },
    async obtener_data() {
      return {}
    },
  },
  computed: {
    correoErrors() {
      const errors = [];
      if (!this.$v.correoIngresado.$dirty) return errors;
      if (!this.$v.correoIngresado?.required) {
        errors.push("Este campo es requerido");
      } else if (!this.$v.correoIngresado?.email) {
        errors.push("Ingrese un formato de correo válido.");
      }
      return errors;
    },
  },
  watch: {
    confirmarContraseña: {
      handler(e) {
        if (e != null) {
          if (this.confirmarContraseña != this.contraseña) {
            this.errorConfirmarContraseña = ["Las contraseñas no coinciden"]
          }
          else {
            this.errorConfirmarContraseña = []
          }
        }
      }
    }
  }
}
</script>

<style></style>
