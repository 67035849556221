<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Conocimientos (puedes agregar uno o más conocimientos)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="5" md="5" xl="5">
        <v-autocomplete v-model="conocimientoSeleccionado" label="Conocimientos*" outlined
                        :items="catalogoConocimiento" item-text="nombre" item-value="id"  class="rounded-lg text-wrap" required
                        :error-messages="errorConocimiento"></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="5" md="5" xl="5">
        <v-autocomplete v-model="nivelSeleccionado" label="Nivel*" outlined
                        :items="catalogoNivelConocimiento" item-text="nombre_nivel" item-value="id" class="rounded-lg"
                        required :error-messages="errorNivelConocimiento"></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarConocimiento">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listadoConocimientos" :headers="headersConocimiento"
                      no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarConocimiento(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Experiencia laboral (puedes agregar una o más experiencias)</h3>
      </v-col>
    </v-row>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field label="Experiencia*" outlined v-model="puestoLaboralIngresado"
                        :maxlength="50"
                        counter
                        @blur="$v.puestoLaboralIngresado.$touch()"
                        :error-messages="puestoLaboralIngresadoErrors"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-menu v-model="desdeExperiencia" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined color="color" v-model="fechaInicioExperienciaFormatted" label="Desde*"
                            prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                            @blur="$v.fechaInicioExperiencia.$touch()"
                            :error-messages="errorFechaInicioExperiencia"
                            clearable
                            ref="fechaInicioExperiencia"></v-text-field>
            </template>
            <v-date-picker v-model="fechaInicioExperiencia" @input="desdeExperiencia = false" locale="es"
                          :format="formatoFecha"
                          :max="fechaActual"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-menu v-model="hastaExperiencia" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined color="color" v-model="fechaFinalizacionExperienciaFormatted" label="Hasta"
                            prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                            clearable
                            :error-messages="errorFechaFinExperiencia"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fechaFinExperiencia" @input="hastaExperiencia = false" locale="es"
                          :format="formatoFecha"
                          :max="fechaActual"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
          <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
                @click="agregarExperiencia">
            Agregar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field label="Lugar de trabajo*" outlined v-model="lugarTrabajoIngresado"
                        :error-messages="lugarTrabajoIngresadoErrors" @blur="$v.lugarTrabajoIngresado.$touch()"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-checkbox v-model="actualmenteTrabajando" label="Actualmente trabajo aquí"></v-checkbox>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field label="Experiencia*" outlined v-model="puestoLaboralIngresado"
                        :maxlength="50"
                        counter
                        @blur="$v.puestoLaboralIngresado.$touch()"
                        :error-messages="puestoLaboralIngresadoErrors"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-menu v-model="desdeExperiencia" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined color="color" v-model="fechaInicioExperienciaFormatted" label="Desde*"
                            prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                            @blur="$v.fechaInicioExperiencia.$touch()"
                            :error-messages="errorFechaInicioExperiencia"
                            clearable
                            ref="fechaInicioExperiencia"></v-text-field>
            </template>
            <v-date-picker v-model="fechaInicioExperiencia" @input="desdeExperiencia = false" locale="es"
                          :format="formatoFecha"
                          :max="fechaActual"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-menu v-model="hastaExperiencia" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined color="color" v-model="fechaFinalizacionExperienciaFormatted" label="Hasta"
                            prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                            clearable
                            :error-messages="errorFechaFinExperiencia"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fechaFinExperiencia" @input="hastaExperiencia = false" locale="es"
                          :format="formatoFecha"
                          :max="fechaActual"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field label="Lugar de trabajo*" outlined v-model="lugarTrabajoIngresado"
                        :error-messages="lugarTrabajoIngresadoErrors"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-checkbox v-model="actualmenteTrabajando" label="Actualmente trabajo aquí"></v-checkbox>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
          <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
                @click="agregarExperiencia">
            Agregar
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-data-table :items="listadoExperiencias" :headers="headersExperiencia" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template #[`item.fecha_inicio`]="{ item }">
            {{ moment(item.fecha_inicio).format("DD/MM/YYYY") }}
          </template>
          <template #[`item.fecha_fin`]="{ item }">
            {{ item.trabaja === false ? moment(item.fecha_fin).format("YYYY") : 'ACTUALMENTE' }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarExperiencia(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';
import jwtDecode from 'jwt-decode';
import {required, requiredIf} from "vuelidate/lib/validators";

const noEmojisOrSpecialChars = (value) => {
  const noEmojiPattern = /^[^\uD83C-\uDBFF\uDC00-\uDFFF\u200D\u2600-\u27BF]*$/;
  const noSpecialCharPattern = /^[a-zA-Z0-9áÁéÉíÍóÓúÚñÑ\s]+$/;
  return noEmojiPattern.test(value) && noSpecialCharPattern.test(value);
};

export default {
  name: "Paso4",
  props: {
    /* ArrayPersona: {
      type: Array,
      default: () => {
      }
    } */
  },
  data() {
    return {
      fechaActual: '',
      catalogoConocimiento: [],
      catalogoNivelConocimiento: [],
      formatoFecha: 'dd/mm/yyyy',

      conocimientoSeleccionado: null,
      nivelSeleccionado: null,
      listadoConocimientos: [],
      errorConocimiento: [],
      errorNivelConocimiento: [],
      headersConocimiento: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'nombre_conocimiento'},
        {text: "Nivel", align: "center", sortable: true, value: "nombre_nivel_conocimiento"},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      token: null,
      puestoLaboralIngresado: null,
      fechaInicioExperiencia: null,
      desdeExperiencia: false,
      hastaExperiencia: false,
      fechaFinExperiencia: null,
      lugarTrabajoIngresado: null,
      actualmenteTrabajando: false,
      listadoExperiencias: [],
      headersExperiencia: [
        {text: 'Puesto', align: 'center', sortable: true, value: 'puesto'},
        {text: 'Lugar de trabajo', align: 'center', sortable: false, value: 'lugar_trabajo'},
        {text: 'Desde', align: 'center', sortable: true, value: 'fecha_inicio'},
        {text: 'Hasta', align: 'center', sortable: false, value: 'fecha_fin'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],

      errorPuesto: [],
      errorLugarTrabajo: [],
      dataPersona: null,
    };
  },
  validations: {
    puestoLaboralIngresado: {
      required, noEmojisOrSpecialChars
    },
    lugarTrabajoIngresado: {
      required, noEmojisOrSpecialChars
    },
    fechaInicioExperiencia: {
      required,
      validDateStart: function (value) {
        if (value && !this.actualmenteTrabajando) {
          return value < this.fechaFinExperiencia
        } else {
          return true
        }
      }
    },
    fechaFinExperiencia: {
      required: requiredIf(function () {
        return !this.actualmenteTrabajando
      }),
      validDateEnd: function (value) {
        if (value && !this.actualmenteTrabajando) {
          return value > this.fechaInicioExperiencia
        } else {
          return true
        }
      }
    }
  },
  methods: {
    getFechaActual() {
    // Obtener la fecha actual en zona horaria local
         let today = new Date();
         let localISODate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
          this.fechaActual= localISODate
        },
    async validar_paso() {
      return true
    },
    async obtener_data() {
      return {}
    },

    async agregarConocimiento() {
      try {
        let validez_conocimiento = this.conocimientoValidacion()
        let validez_nivel = this.nivelConocimientoValidacion()

        if (validez_conocimiento == true && validez_nivel == true) {
          let body = {
            id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona,
            id_nivel: this.nivelSeleccionado,
            id_conocimiento: this.conocimientoSeleccionado
          }
          const response = await this.services.formacionPersona.postNivelConocimiento(body)
          if (response.status === 200) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: response.data.message
            })
            await this.listarConocimiento()
          }
        } else {
          this.temporalAlert({
            show: true,
            message: "Favor completar todos los campos",
            type: "error",
          });
        }
        this.conocimientoSeleccionado = null
        this.nivelSeleccionado = null
      } catch (e) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: e.response.data.err.description
        })
      }
    },
    conocimientoValidacion() {
      let validez = false

      if (this.conocimientoSeleccionado != null && this.conocimientoSeleccionado != "" && this.conocimientoSeleccionado.length != 0) {
        validez = true
        this.errorConocimiento = []
      } else {
        validez = false
        this.errorConocimiento = ["Favor seleccionar uno o más conocimientos"]
      }
      return validez
    },
    nivelConocimientoValidacion() {
      let validez = false

      if (this.nivelSeleccionado != null && this.nivelSeleccionado != "") {
        validez = true
        this.errorNivelConocimiento = []
      } else {
        validez = false
        this.errorNivelConocimiento = ["Favor escoger un nivel de conocimiento"]
      }
      return validez
    },
    async eliminarConocimiento(id) {
      const response = await this.services.formacionPersona.deleteNivelConocimiento(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message
        })
        await this.listarConocimiento()
      }
    },
    async agregarExperiencia() {
      try {
        this.$v.puestoLaboralIngresado.$touch()
        this.$v.lugarTrabajoIngresado.$touch()
        this.$v.fechaInicioExperiencia.$touch()
        this.$v.fechaFinExperiencia.$touch()
        if (this.$v.puestoLaboralIngresado.$invalid || this.$v.lugarTrabajoIngresado.$invalid || this.$v.fechaInicioExperiencia.$invalid || this.$v.fechaFinExperiencia.$invalid) {
          this.temporalAlert({
            show: true,
            message: "Completa correctamente todos los campos",
            type: "warning",
          });
          return
        }

        const body = {}
        body.id_persona = this.token ? this.dataPersona.id : this.dataPersona.id_persona
        body.fecha_inicio = this.fechaInicioExperiencia
        if (this.fechaFinExperiencia !== null) {
          body.fecha_fin = this.fechaFinExperiencia
        } else {
          body.fecha_fin = '2023-09-04'
        }
        body.trabaja = this.actualmenteTrabajando
        body.lugar_trabajo = this.lugarTrabajoIngresado
        body.puesto = this.puestoLaboralIngresado
        const response = await this.services.formacionPersona.postExperiencia(body)
        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Experiencia creada con éxito"
          })
          this.$v.puestoLaboralIngresado.$reset()
          this.$v.lugarTrabajoIngresado.$reset()
          this.$v.fechaInicioExperiencia.$reset()
          this.$v.fechaFinExperiencia.$reset()
          this.puestoLaboralIngresado = null
          this.lugarTrabajoIngresado = null
          this.fechaInicioExperiencia = null
          this.fechaFinExperiencia = null
          this.actualmenteTrabajando = false
          await this.listarExperiencia()
        }
      } catch (e) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: e.response.data.err.description
        })
      }

      // this.puestoLaboralIngresado = null
      // this.fechaInicioExperiencia = null
      // this.fechaFinExperiencia = null
      // this.lugarTrabajoIngresado = null
      // this.actualmenteTrabajando = null
      // this.$refs.fechaInicioExperiencia.reset()
    },
    async eliminarExperiencia(id) {
      const response = await this.services.formacionPersona.deleteExperiencia(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Experiencia laboral eliminada con éxito"
        })
        await this.listarExperiencia()
      }
    },
    async listarConocimiento() {
      const response = await this.services.formacionPersona.getConocimientoPersona({
        id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona,
      })
      this.listadoConocimientos = response.data
    },
    async listarExperiencia() {
      const response = await this.services.formacionPersona.getExperiencia({
        id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona
      })
      this.listadoExperiencias = response.data
    },
    puestoValidacion() {
      let validez = false

      if (this.puestoLaboralIngresado != null && this.puestoLaboralIngresado != "") {
        validez = true
        this.errorPuesto = []
      } else {
        validez = false
        this.errorPuesto = ["Favor ingresar su puesto laboral"]
      }
      return validez
    },
    lugarTrabajoValidacion() {
      let validez = false

      if (this.lugarTrabajoIngresado != null && this.lugarTrabajoIngresado != "") {
        validez = true
        this.errorLugarTrabajo = []
      } else {
        validez = false
        this.errorLugarTrabajo = ["Favor ingrese su lugar de trabajo"]
      }
      return validez
    },

    async getConocimientos() {
      try {
        const response = await this.services.datosPersona.getConocimiento({
          paginacion: false
        });
        
        let data = response.data.map(item => {
          const maxLength = 80; // Máxima longitud permitida antes de truncar
          if (item.nombre.length > maxLength) {
            return {
              ...item,
              nombre: item.nombre.substring(0, maxLength) + "...",
            };
          } else {
            return item;
          }
        });

        data.sort((a, b) => a.nombre.localeCompare(b.nombre)); // Ordena alfabéticamente

        this.catalogoConocimiento = data;
      } catch (error) {
        console.error("Error al obtener conocimientos:", error);
        this.temporalAlert({
          show: true,
          message: "Error al obtener los conocimientos.",
          type: "error",
        });
      }
    },
    async getNivelConocimiento() {
      const response = await this.services.datosPersona.getNivelConocimiento()
      this.catalogoNivelConocimiento = response.data
    },
  },
  mounted(){this.getFechaActual()},
  computed: {
    ...mapState(["userInfo"]),
    fechaInicioExperienciaFormatted: {
      get() {
        if (this.fechaInicioExperiencia) return this.moment(this.fechaInicioExperiencia).format("DD/MM/YYYY")
      },
      set() {
        return
      }
    },
    fechaFinalizacionExperienciaFormatted: {
      get() {
        if (this.fechaFinExperiencia) return this.moment(this.fechaFinExperiencia).format("DD/MM/YYYY")
      },
      set() {
        return
      }
    },
    puestoLaboralIngresadoErrors() {
      let errors = [];
      if (!this.$v.puestoLaboralIngresado?.$dirty) return errors
      !this.$v.puestoLaboralIngresado?.required && errors.push("Campo requerido")
      !this.$v.puestoLaboralIngresado.noEmojisOrSpecialChars && errors.push("Caracteres inválidos");
      return errors;
    },
    lugarTrabajoIngresadoErrors() {
      let errors = [];
      if (!this.$v.lugarTrabajoIngresado?.$dirty) return errors
      !this.$v.lugarTrabajoIngresado?.required && errors.push("Campo requerido")
      !this.$v.lugarTrabajoIngresado.noEmojisOrSpecialChars && errors.push("Caracteres inválidos");
      return errors;
    },
    errorFechaInicioExperiencia() {
      let errors = [];
      if (!this.$v.fechaInicioExperiencia?.$dirty) return errors
      !this.$v.fechaInicioExperiencia?.required && errors.push("Campo requerido")
      !this.$v.fechaInicioExperiencia.validDateStart && errors.push('Fecha de inicio debe ser menor a la fecha fin')
      return errors;
      // const errors = []
      // if (this.fechaInicioExperiencia > this.fechaFinExperiencia) {
      //   errors.push("Favor elegir una fecha menor o igual a la de finalización")
      // }
      // return errors
    },
    errorFechaFinExperiencia() {
      let errors = [];
      if (!this.$v.fechaFinExperiencia?.$dirty) return errors
      !this.$v.fechaFinExperiencia?.required && errors.push("Campo requerido")
      !this.$v.fechaFinExperiencia.validDateEnd && errors.push('Fecha de fin tiene que ser mayor a la fecha de inicio')
      return errors;
      // const errors = []
      // if (this.fechaInicioExperiencia > this.fechaFinExperiencia) {
      //   errors.push("Favor elegir una fecha mayor o igual a la de inicio")
      // }
      // return errors
    },
  },
  async created() {
    await this.getConocimientos()
    await this.getNivelConocimiento()
    this.token = localStorage.getItem('token')
    if (this.token) {
      const userDecode = jwtDecode(this.token)
      this.dataPersona = userDecode.persona
    }
    await this.listarConocimiento()
    await this.listarExperiencia()
  }
}
</script>

<style></style>
