<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Capacidades (puedes agregar una o más capacidades)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="10" md="10" xl="10">
        <v-autocomplete v-model="capacidadesSeleccionadas" label="Capacidades*" outlined
                        :items="catalogoCapacidades" item-text="nombre" item-value="id" class="rounded-lg" required
                        :error-messages="errorCapacidades"></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarCapacidades">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listaCapacidades" :headers="headersCapacidades" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarCapacidad(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Otros aspectos (puedes agregar uno o más aspectos)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="10" md="10" xl="10">
        <v-text-field v-model="aspectosSeleccionados" label="Otros aspectos*" outlined
                      @keypress="soloTexto" @paste="soloTexto"
                      counter maxlength="50"
                      class="rounded-lg" required :error-messages="errorAspectos" @blur="$v.aspectosSeleccionados.$touch()"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarAspectos">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listaAspectos" :headers="headersAspectos" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarAspecto(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Áreas de interés (agrega áreas que sean de tu interés para mejorar la experiencia de uso)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="10" md="10" xl="10">
        <v-autocomplete v-model="areasInteresSeleccionadas" label="Áreas de interés*" outlined
                        :items="catalogoAreasInteres" item-text="nombre_area" item-value="id" class="rounded-lg"
                        required :error-messages="errorAreas"></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarAreas">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listaAreas" :headers="headersAreas" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarAreaInteres(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwtDecode from "jwt-decode";
import {mapState} from "vuex";
import {required} from "vuelidate/lib/validators";


const noEmojisOrSpecialChars = (value) => {
  const noEmojiPattern = /^[^\uD83C-\uDBFF\uDC00-\uDFFF\u200D\u2600-\u27BF]*$/;
  const noSpecialCharPattern = /^[a-zA-Z0-9áÁéÉíÍóÓúÚñÑ\s]+$/;
  return noEmojiPattern.test(value) && noSpecialCharPattern.test(value);
};

export default {
  name: "Paso5",
  props: {
    /* ArrayPersona: {
      type: Array,
      default: () => {
      }
    } */
  },
  data() {
    return {
      catalogoCapacidades: [],
      catalogoOtrosAspectos: [],
      catalogoAreasInteres: [],

      capacidadesSeleccionadas: null,
      errorCapacidades: [],
      listaCapacidades: [],
      headersCapacidades: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'Capacidad.nombre'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      token: null,
      aspectosSeleccionados: null,
      // errorAspectos: [],
      listaAspectos: [],
      headersAspectos: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'nombre'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],

      areasInteresSeleccionadas: null,
      errorAreas: [],
      listaAreas: [],
      headersAreas: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'nombre_area_interes'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      dataPersona: null,
    };
  },
  validations: {
    aspectosSeleccionados: {noEmojisOrSpecialChars}
  },
  methods: {
    async validar_paso() {
      return true
    },
    async obtener_data() {
      return {}
    },
    soloTexto(e) {
      const char = String.fromCharCode(e.keyCode);
      let patron = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/;

      if (!patron.test(char)) {
        e.preventDefault();
      }
      return true;
    },


    async agregarCapacidades() {
     try {
       if (this.capacidadesSeleccionadas != null && this.capacidadesSeleccionadas != "") {
         this.errorCapacidades = []
         const response = await this.services.capacidad.postCapacidadPersona({
           id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona,
           id_capacidad: this.capacidadesSeleccionadas
         })
         if (response.status == 200) {
           this.temporalAlert({
             show: true,
             type: "success",
             message: response.data.message
           })
           this.capacidadesSeleccionadas = null
           await this.listarCapacidades()
         }
       } else {
         this.errorCapacidades = ["Favor seleccionar una capacidad"]
       }
     }catch (e) {
       this.temporalAlert({
         show: true,
         type: "error",
         message: e.response.data.err.description
       })
     }
    },

    async eliminarCapacidad(id) {
      const response = await this.services.capacidad.deleteCapacidadPersona(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message
        })
        await this.listarCapacidades()
      }
    },
    async agregarAspectos() {
      this.$v.aspectosSeleccionados.$touch();
      if (!this.$v.aspectosSeleccionados.$pending && !this.$v.aspectosSeleccionados.$invalid) {
        if (this.aspectosSeleccionados != null && this.aspectosSeleccionados != "") {
          const response = await this.services.capacidad.postOtroAspecto({
            id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona,
            nombre: this.aspectosSeleccionados,
          });
          if (response.status == 200) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: response.data.message,
            });
            this.aspectosSeleccionados = null;
            await this.listarOtrosAspectos();
          }
        } else {
          this.errorAspectos = ["Favor elegir un aspecto"];
        }
      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Por favor, corrige los errores antes de continuar.",
        });
      }
    },
    async eliminarAspecto(id) {
      const response = await this.services.capacidad.deleteOtroAspecto(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message
        })
        await this.listarOtrosAspectos()
      }
    },
    async agregarAreas() {
      try {
        if (this.areasInteresSeleccionadas != null && this.areasInteresSeleccionadas != "") {
          this.errorAreas = []

          const response = await this.services.capacidad.postAreaInteres({
            id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona,
            id_area_interes: this.areasInteresSeleccionadas
          })
          if (response.status == 200) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: response.data.message
            })
            this.areasInteresSeleccionadas = null
            await this.listarAreasInteres()
          }
        } else {
          this.errorAreas = ["Favor elegir una area de interés"]
        }
      }catch (e) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: e.response.data.err.description
        })
      }
    },
    async eliminarAreaInteres(id) {
      const response = await this.services.capacidad.deleteAreaInteres(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message
        })
        await this.listarAreasInteres()
      }
    },
    async getCapacidades() {
      const response = await this.services.datosPersona.getCapacidad({
        paginacion: false
      })
      this.catalogoCapacidades = response.data
    },
    async getAreaInteres() {
      const response = await this.services.OfertaEmpleo.getAreaInteres({
        paginacion: false
      })
      if (response.status == 200) {
        this.catalogoAreasInteres = response.data
      }
    },
    async listarCapacidades() {
      const response = await this.services.capacidad.getCapacidadPersona({
        id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona
      })
      this.listaCapacidades = response.data
    },
    async listarOtrosAspectos() {
      const response = await this.services.capacidad.getOtrosAspectos({
        id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona
      })
      this.listaAspectos = response.data
    },
    async listarAreasInteres() {
      const response = await this.services.capacidad.getAreaInteres({
        id_persona: this.token ? this.dataPersona.id : this.dataPersona.id_persona,
      })
      this.listaAreas = response.data
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    errorAspectos() {
      const errors = [];
      if (!this.$v.aspectosSeleccionados.$dirty) return errors;
      if (!this.$v.aspectosSeleccionados.noEmojisOrSpecialChars && this.$v.aspectosSeleccionados.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
  },
  async created() {
    await this.getCapacidades()
    await this.getAreaInteres()
    this.token = localStorage.getItem('token')
    if (this.token) {
      const userDecode = jwtDecode(this.token)
      this.dataPersona = userDecode.persona
    }
    await this.listarCapacidades()
    await this.listarOtrosAspectos()
    await this.listarAreasInteres()
  }
}
</script>

<style></style>
