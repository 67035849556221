<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8" xl="8">
        <v-row>
          <v-col cols="12">
            <h3>Formación</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="12" lg="5" md="5" xl="5">
            <v-autocomplete v-model="nivelEducativoSeleccionado" label="Nivel educativo*" outlined
                            :items="catalogoNivelEducativo" item-text="nombre" item-value="id" class="rounded-lg"
                            required
                            @blur="$v.nivelEducativoSeleccionado.$touch()"
                            :error-messages="nivelEducativoSeleccionadoErrors"
                            @change="getFormacionEducativa(nivelEducativoSeleccionado)"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" sm="12" lg="5" md="5" xl="5">
            <v-autocomplete v-model="tituloObtenidoSeleccionado" label="Título obtenido*" outlined
                            :items="catalogoTituloObtenido" item-text="nombre" item-value="id" class="rounded-lg"
                            required
                            @blur="$v.tituloObtenidoSeleccionado.$touch()"
                            :error-messages="tituloObtenidoSeleccionadoErrors"
                            :disabled="nivelEducativoSeleccionado === null"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="12" lg="5" md="5" xl="5">
            <v-menu v-model="desdeFormacion" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined color="color" v-model="fechaInicioFormacionFormatted" label="Desde*"
                              prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                              @blur="$v.fechaInicioFormacion.$touch()"
                              :error-messages="errorFechaInicioFormacion"
                              ref="fechaInicioFormacion"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fechaInicioFormacion" @input="desdeFormacion = false" locale="es"
                             :format="formatoFecha" :max="fechaActual"></v-date-picker>
                             
            </v-menu>
          </v-col>
          
          <v-col cols="12" xs="12" sm="12" lg="5" md="5" xl="5">
            <v-menu v-model="hastaFormacion" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined color="color" v-model="fechaFinalizacionFormacionFormatted" label="Hasta"
                              prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                              @blur="$v.fechaFinalizacionFormacion.$touch()"
                              :error-messages="errorFechaFinFormacion"></v-text-field>
              </template>
              <v-date-picker v-model="fechaFinalizacionFormacion" @input="hastaCertificacion = false" locale="es"
                             :format="formatoFecha" :min="fechaMinima" :max="fechaActual"
                             :disabled="actualmenteEstudiandoFormacion"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-row>
          <v-col class="pb-0">
            <h3>Institución</h3>
          </v-col>
        </v-row>
        <v-row class="pb-0 pt-0">
          <v-col class="pb-0 pt-0">
            <v-radio-group v-model="esNacional" row :error-messages="esNacionalErrors" @change="$v.esNacional.$touch()">
              <v-radio label="Nacional" color="blueConaipd" :value="true"></v-radio>
              <v-radio label="Extranjero" color="blueConaipd" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="pt-0 pb-0">
          <v-col cols="12" xs="12" sm="12" lg="12" md="12" xl="12" class="pt-0 pb-0">
            <v-text-field label="Nombre institución*" outlined v-model="nombreInstitucionIngresado"
                          @blur="$v.nombreInstitucionIngresado.$touch()"
                          :error-messages="nombreInstitucionIngresadoErrors"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pt-0 pb-0">
          <v-col class="pt-0 pb-0">
            <v-checkbox v-model="actualmenteEstudiandoFormacion" label="Actualmente sigo estudiando"
                        @change="formacionSigoEstudiando(actualmenteEstudiandoFormacion)"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="10" md="10" xl="10">
        <v-file-input accept="image/png, image/jpg, image/jpeg, application/pdf" prepend-icon=""
                      append-icon="mdi-tray-arrow-down" label="Adjuntar documento" outlined
                      v-model="tituloObtenidoAdjunto"
                      @blur="$v.tituloObtenidoAdjunto.$touch()"
                      :error-messages="tituloObtenidoAdjuntoErrors"
                      truncate-length="15"><!-- :error-messages="fotoPerfilError" -->
          <!-- @click:clear="eliminarFotoDocumento()" --></v-file-input>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarFormacion">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listadoFormacion" :headers="headersFormacion" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template #[`item.fecha_inicio`]="{ item }">
            {{ moment(item.Formacion.fecha_inicio).format("YYYY") }}
          </template>
          <template #[`item.fecha_fin`]="{ item }">
            {{ formatFechaFin(item) }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="showDocument('I', item.Formacion.id)" v-if="item.Formacion?.ruta_documento !== null"
                        v-bind="attrs"
                        v-on="on">
                  mdi-eye
                </v-icon>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarFormacionEducativa(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Certificados y diplomas</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-text-field label="Certificado o diploma*" outlined v-model="certificadoIngresado"
                      :error-messages="errorCertificado" @blur="$v.certificadoIngresado.$touch()"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-text-field label="Institución*" outlined v-model="institucionIngresada"
                      :error-messages="errorInstitutoCertificado" @blur="$v.institucionIngresada.$touch()"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-menu v-model="desdeCertificacion" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined color="color" v-model="fechaInicioCertificadoFormatted" label="Desde*"
                          ref="fechaInicioCertificado"
                          prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                          :error-messages="errorFechaInicioCertificado"
                          :rules="[(v) => !!v || 'Por favor seleccionar una fecha']"></v-text-field>
          </template>
          <v-date-picker v-model="fechaInicioCertificado" @input="desdeCertificacion = false" locale="es"
                         :format="formatoFecha" :max="fechaActual"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-menu v-model="hastaCertificacion" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined color="color" v-model="fechaFinalizacionCertificadoFormatted" label="Hasta"
                          prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                          :error-messages="errorFechaFinCertificado"></v-text-field>
          </template>
          <v-date-picker v-model="fechaFinalizacionCertificado" @input="hastaCertificacion = false" locale="es"
                         :format="formatoFecha" :min="fechaMinimaC" :max="fechaActual" :disabled="actualmenteEstudiando"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-checkbox v-model="actualmenteEstudiando" label="Actualmente sigo estudiando"
                    @change="formacionCertificadoSigoEstudiando(actualmenteEstudiando)"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="6" md="6" xl="6">
        <v-file-input accept="image/png, image/jpeg, image/bmp, application/pdf" prepend-icon=""
                      append-icon="mdi-tray-arrow-down" label="Adjuntar documento" outlined
                      v-model="certificadoIngresadoAdjunto"
                      truncate-length="15"><!-- :error-messages="fotoPerfilError" -->
          <!-- @click:clear="eliminarFotoDocumento()" --></v-file-input>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarCertificado">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listadoCertificados" :headers="headersCertificado" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template #[`item.fecha_inicio`]="{ item }">
            {{ moment(item.Formacion.fecha_inicio).format("YYYY") }}
          </template>
          <template #[`item.fecha_fin`]="{ item }">
            {{ formatFechaFin(item) }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="showDocument('I', item.Formacion.id)"
                        :class="item.Formacion?.ruta_documento !== null ? '' : 'ocultar-elemento'"
                        v-bind="attrs"
                        v-on="on">
                  mdi-eye
                </v-icon>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarFormacion(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <template>
      <v-dialog v-model="showDialogDocumento" max-width="800px">
        <v-card class="pb-2">
          <embed width="100%" height="700" :src="verDocuemnto" :type="typeDoc"/>
          <v-card-actions>
            <v-btn color="#22234a" class="my-text-button" dark rounded @click="closeDialogDocumento">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {required, requiredIf} from "vuelidate/lib/validators";

const noEmojisOrSpecialChars = (value) => {
  const noEmojiPattern = /^[^\uD83C-\uDBFF\uDC00-\uDFFF\u200D\u2600-\u27BF]*$/;
  const noSpecialCharPattern = /^[a-zA-Z0-9áÁéÉíÍóÓúÚñÑ\s]+$/;
  return noEmojiPattern.test(value) && noSpecialCharPattern.test(value);
};

const validtypeDocBoolean = (value, vm, typesExtention) => {
  if (value) {
    return typesExtention.some((ext) => ext === value.type)
  } else {
    return false
  }
}
export default {
  name: "Paso3",
  props: {
    /* ArrayPersona: {
      type: Array,
      default: () => {
      }
    } */
  },
  data() {
    return {
      fechaActual: '',
      catalogoNivelEducativo: [],
      catalogoTituloObtenido: [],
      fechaMinima: null,
      fechaMinimaC: null,

      desdeFormacion: false,
      hastaFormacion: false,
      formatoFecha: 'dd/mm/yyyy',
      nivelEducativoSeleccionado: null,
      tituloObtenidoSeleccionado: null,
      fechaInicioFormacion: null,
      fechaFinalizacionFormacion: null,
      tituloObtenidoAdjunto: null,
      esNacional: null,
      nombreInstitucionIngresado: null,
      continuaEstudiando: null,
      listadoFormacion: [],
      token: null,
      headersFormacion: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'Formacion.FormacionEducativa.nombre'},
        {text: 'Institucion', align: 'center', sortable: false, value: 'Formacion.nombre_institucion'},
        {text: 'Desde', align: 'center', sortable: true, value: 'fecha_inicio'},
        {text: 'Hasta', align: 'center', sortable: false, value: 'fecha_fin'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      errorNivelEducativo: [],
      errorTituloObtenido: [],

      errorNombreInstitucion: [],
      errorAdjuntarFormacion: [],
      errorTipoInstitucion: [],

      desdeCertificacion: false,
      hastaCertificacion: false,
      certificadoIngresado: null,
      institucionIngresada: null,
      fechaInicioCertificado: null,
      fechaFinalizacionCertificado: null,
      actualmenteEstudiando: false,
      certificadoIngresadoAdjunto: null,
      listadoCertificados: [],
      headersCertificado: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'Formacion.otro_titulo'},
        {text: 'Institucion', align: 'center', sortable: false, value: 'Formacion.nombre_institucion'},
        {text: 'Desde', align: 'center', sortable: true, value: 'fecha_inicio'},
        {text: 'Hasta', align: 'center', sortable: false, value: 'fecha_fin'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      // errorCertificado: [],
      // errorInstitutoCertificado: [],

      errorSigoEstudiando: [],
      errorAdjuntarCertificado: [],
      actualmenteEstudiandoFormacion: false,
      dataPersona: null,
      showDialogDocumento: false,
      verDocuemnto: null,
      typeDoc: null,
    };
  },
  validations: {
    certificadoIngresado: {required, noEmojisOrSpecialChars},
    institucionIngresada: {required, noEmojisOrSpecialChars},
    nivelEducativoSeleccionado: {
      required
    },
    tituloObtenidoSeleccionado: {
      required
    },
    fechaInicioFormacion: {
      required
    },
    fechaFinalizacionFormacion: {
      required: requiredIf(function () {
        return !this.actualmenteEstudiandoFormacion
      })
    },
    esNacional: {
      required
    },
    nombreInstitucionIngresado: {
      required, noEmojisOrSpecialChars
    },
    tituloObtenidoAdjunto: {
      validFormat: function (value) {
        if (value) {
          const extentions = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
          return validtypeDocBoolean(value, this, extentions)
        } else {
          return true
        }
      }
    }
  },
  methods: {
    getFechaActual() {
    // Obtener la fecha actual en zona horaria local
         let today = new Date();
         let localISODate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
          this.fechaActual =  localISODate
        },
    formatFechaFin(item) {
      if (item.Formacion.estudia === false) {
        const fechaFinMoment = moment(item.Formacion.fecha_fin);
        return fechaFinMoment.isValid() ? fechaFinMoment.format("YYYY") : '-';
      } else {
        return 'Actualidad';
      }
    },
    async validar_paso() {
      return true
    },
    async obtener_data() {
      return {}
    },
    async listarFormacionPersona() {
      const tipoFormacion = await this.services.formacionPersona.getTipoFormacion({
        paginacion: false
      })
      if (tipoFormacion?.data[0]?.id === 1) {
        const response = await this.services.formacionPersona.getFormacionPersona({
          id_persona: this.dataPersona.id,
          id_tipo_formacion: 1,
        })
        this.listadoFormacion = response.data
      }
      if (tipoFormacion?.data[1]?.id === 2) {
        const response = await this.services.formacionPersona.getFormacionPersona({
          id_persona: this.dataPersona.id,
          id_tipo_formacion: 2,

        })
        this.listadoCertificados = response.data
      }
    },
    async agregarFormacion() {
      // let validez_nivel_educativo = this.nivelEducativoValidacion()
      // let validez_titulo = this.tituloObtenidoValidacion()
      this.$v.nivelEducativoSeleccionado.$touch()
      this.$v.tituloObtenidoSeleccionado.$touch()
      this.$v.fechaFinalizacionFormacion.$touch()
      this.$v.fechaInicioFormacion.$touch()
      this.$v.esNacional.$touch()
      this.$v.nombreInstitucionIngresado.$touch()
      this.$v.tituloObtenidoAdjunto.$touch()
      // let validez_institucion = this.institucionValidacion()
      // let validez_fechas = this.fechasFormacionValidacion()
      // let validez_nombre_institucion = this.nombreInstitucionValidacion()
      // let validez_documento = this.documentoFormacionValidacion()
      if (
          this.$v.nivelEducativoSeleccionado.$invalid ||
          this.$v.tituloObtenidoSeleccionado.$invalid ||
          this.$v.fechaFinalizacionFormacion.$invalid ||
          this.$v.fechaInicioFormacion.$invalid ||
          this.$v.esNacional.$invalid ||
          this.$v.nombreInstitucionIngresado.$invalid ||
          this.$v.tituloObtenidoAdjunto.$invalid
      ) {
        this.temporalAlert({
          show: true,
          message: "Completa correctamente los campos requeridos",
          type: "warning",
        });
        return
      }
      // if (this.actualmenteEstudiandoFormacion === false && (this.fechaFinalizacionFormacion === null || this.fechaFinalizacionFormacion === '')) {
      //   this.temporalAlert({
      //     show: true,
      //     message: "Selecciona una fecha de finalización",
      //     type: "error",
      //   });
      //   return
      // }
      // if (validez_nivel_educativo == true && validez_titulo == true && validez_institucion == true && validez_nombre_institucion == true
      //     && this.fechaInicioFormacion !== null) {
      const formData = new FormData();
      formData.append("nombre_institucion", this.nombreInstitucionIngresado)
      formData.append("fecha_inicio", this.fechaInicioFormacion)
      if (this.fechaFinalizacionFormacion) {
        formData.append("fecha_fin", this.fechaFinalizacionFormacion)
      }
      formData.append("estudia", this.actualmenteEstudiandoFormacion ?? false)
      formData.append("nacional", this.esNacional)
      formData.append("id_tipo_formacion", 1)
      formData.append("id_formacion_educativa", this.tituloObtenidoSeleccionado)
      formData.append("id_persona", this.token ? this.dataPersona.id : this.dataPersona.id_persona)
      formData.append("archivo", this.tituloObtenidoAdjunto)
      const response = await this.services.formacionPersona.postFormacionPersona(formData)
      if (response.status === 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Formación educativa creado con éxito"
        })
        this.$v.nivelEducativoSeleccionado.$reset()
        this.$v.tituloObtenidoSeleccionado.$reset()
        this.$v.fechaFinalizacionFormacion.$reset()
        this.$v.fechaInicioFormacion.$reset()
        this.$v.esNacional.$reset()
        this.$v.nombreInstitucionIngresado.$reset()
        this.$v.tituloObtenidoAdjunto.$reset()

        this.nivelEducativoSeleccionado = null
        this.tituloObtenidoSeleccionado = null
        this.fechaFinalizacionFormacion = null
        this.fechaInicioFormacion = null
        this.esNacional = null
        this.nombreInstitucionIngresado = null
        this.tituloObtenidoAdjunto = null
        await this.listarFormacionPersona()
      }

      // } else {
      //   this.temporalAlert({
      //     show: true,
      //     message: "Favor completar todos los campos",
      //     type: "error",
      //   });
      // }

      this.nivelEducativoSeleccionado = null
      this.tituloObtenidoSeleccionado = null
      this.esNacional = null
      this.fechaInicioFormacion = ''
      this.fechaFinalizacionFormacion = null
      this.nombreInstitucionIngresado = null
      this.actualmenteEstudiandoFormacion = false
      this.tituloObtenidoAdjunto = null
      this.$refs.fechaInicioFormacion.reset()
    },
    // nivelEducativoValidacion() {
    //   let validez = false
    //
    //   if (this.nivelEducativoSeleccionado != null && this.nivelEducativoSeleccionado != "") {
    //     validez = true
    //     this.errorNivelEducativo = []
    //   } else {
    //     validez = false
    //     this.errorNivelEducativo = ["Favor seleccione un nivel educativo"]
    //   }
    //   return validez
    // },
    // tituloObtenidoValidacion() {
    //   let validez = false
    //
    //   if (this.tituloObtenidoSeleccionado != null && this.tituloObtenidoSeleccionado != "") {
    //     validez = true
    //     this.errorTituloObtenido = []
    //   } else {
    //     validez = false
    //     this.errorTituloObtenido = ["Favor seleccionar un titulo obtenido"]
    //   }
    //   return validez
    // },
    // institucionValidacion() {
    //   let validez = false
    //
    //   if (this.esNacional != null) {
    //     validez = true
    //     this.errorTipoInstitucion = []
    //   } else {
    //     validez = false
    //     this.errorTipoInstitucion = ["Favor elegir un tipo de institución"]
    //   }
    //   return validez
    // },
    fechasFormacionValidacion() {

    },
    // nombreInstitucionValidacion() {
    //   let validez = false
    //
    //   if (this.nombreInstitucionIngresado != null && this.nombreInstitucionIngresado != "") {
    //     validez = true
    //     this.errorNombreInstitucion = []
    //   } else {
    //     validez = false
    //     this.errorNombreInstitucion = ["Favor ingresar el nombre de una institución"]
    //   }
    //   return validez
    // },

    async eliminarFormacionEducativa(id) {
      const response = await this.services.formacionPersona.deleteFormacionPersona(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Formación educativa eliminada con éxito"
        })
        await this.listarFormacionPersona()
      }
    },
    async eliminarFormacion(id) {
      const response = await this.services.formacionPersona.deleteFormacionPersona(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Formación eliminada con éxito"
        })
        await this.listarFormacionPersona()
      }
    },
    async agregarCertificado() {
  this.$v.certificadoIngresado.$touch();
  this.$v.institucionIngresada.$touch();

  let validez_certificado = this.certificadoIngresadoValidacion();
  let validez_instituto_certificado = this.institutoCertificadoValidacion();
  
  if (!validez_certificado || !validez_instituto_certificado) {
    this.temporalAlert({
      show: true,
      message: "Completa correctamente los campos",
      type: "error",
    });
    return;
  }

  // Validación de fecha de inicio
  if (!this.fechaInicioCertificado) {
    this.temporalAlert({
      show: true,
      message: "Seleccione una fecha de inicio",
      type: "error",
    });
    return;
  }

  // Validación de fecha de finalización si no está estudiando
  if (!this.actualmenteEstudiando && !this.fechaFinalizacionCertificado) {
    this.temporalAlert({
      show: true,
      message: "Seleccione una fecha de finalización",
      type: "error",
    });
    return;
  }

  // Validar que todos los campos marcados con $v son válidos
  if (this.$v.certificadoIngresado.$invalid || this.$v.institucionIngresada.$invalid) {
    this.temporalAlert({
      show: true,
      message: "Completa correctamente los campos",
      type: "error",
    });
    return;
  }
  
  try {
    // Preparar datos para el envío
    const formData = new FormData();
    formData.append("nombre_institucion", this.institucionIngresada);
    formData.append("fecha_inicio", this.fechaInicioCertificado);
    if (this.fechaFinalizacionCertificado) {
      formData.append("fecha_fin", this.fechaFinalizacionCertificado);
    }
    formData.append("estudia", this.actualmenteEstudiando ?? false);
    formData.append("id_tipo_formacion", 2);
    formData.append("otro_titulo", this.certificadoIngresado);
    formData.append("id_persona", this.token ? this.dataPersona.id : this.dataPersona.id_persona);
    formData.append("archivo", this.certificadoIngresadoAdjunto);

    // Enviar datos al servidor
    const response = await this.services.formacionPersona.postFormacionPersona(formData);

    if (response.status === 201) {
      // Alerta de éxito
      this.temporalAlert({
        show: true,
        type: "success",
        message: "Formación certificado creado con éxito",
      });
      await this.listarFormacionPersona();

      // Reiniciar campos
      this.certificadoIngresado = null;
      this.institucionIngresada = null;
      this.fechaInicioCertificado = '';
      this.fechaFinalizacionCertificado = null;
      this.actualmenteEstudiando = false;
      this.certificadoIngresadoAdjunto = null;
      this.$refs.fechaInicioCertificado.reset();
      this.$v.certificadoIngresado.$reset();
      this.$v.institucionIngresada.$reset();
    }
  } catch (error) {
    // Manejo de errores
    this.temporalAlert({
      show: true,
      type: "error",
      message: "Ocurrió un error, por favor intente nuevamente.",
    });
  }
},
    // async agregarCertificado() {

    //   let validez_certificado = this.certificadoIngresadoValidacion()
    //   let validez_instituto_certificado = this.institutoCertificadoValidacion()
    //   // let validez_fechas = this.fechasCertificacionValidacion()
    //   if (this.fechaInicioCertificado == null || this.fechaInicioCertificado == '') {
    //     this.temporalAlert({
    //       show: true,
    //       message: "Seleccione una fecha de inicio",
    //       type: "error",
    //     });
    //     return
    //   }
    //   if (this.actualmenteEstudiando === false && (this.fechaFinalizacionCertificado === null || this.fechaFinalizacionCertificado === '')) {
    //     this.temporalAlert({
    //       show: true,
    //       message: "Seleccione una fecha de finalización",
    //       type: "error",
    //     });
    //     return
    //   }
    //   if (validez_certificado == true && validez_instituto_certificado == true && this.fechaInicioCertificado !== null) {
    //     const formData = new FormData();
    //     formData.append("nombre_institucion", this.institucionIngresada)
    //     formData.append("fecha_inicio", this.fechaInicioCertificado)
    //     if (this.fechaFinalizacionCertificado !== null) {
    //       formData.append("fecha_fin", this.fechaFinalizacionCertificado)
    //     }
    //     formData.append("estudia", this.actualmenteEstudiando ?? false)
    //     formData.append("id_tipo_formacion", 2)
    //     formData.append("otro_titulo", this.certificadoIngresado)
    //     formData.append("id_persona", this.token ? this.dataPersona.id : this.dataPersona.id_persona)
    //     formData.append("archivo", this.certificadoIngresadoAdjunto)
    //     const response = await this.services.formacionPersona.postFormacionPersona(formData)
    //     if (response.status === 201) {
    //       this.temporalAlert({
    //         show: true,
    //         type: "success",
    //         message: "Formación certificado creado con éxito"
    //       })
    //       await this.listarFormacionPersona()
    //     }
    //   } else {
    //     this.temporalAlert({
    //       show: true,
    //       message: "Favor completar todos los campos",
    //       type: "error",
    //     });
    //   }
    //   this.certificadoIngresado = null
    //   this.institucionIngresada = null
    //   this.fechaInicioCertificado = ''
    //   this.fechaFinalizacionCertificado = null
    //   this.actualmenteEstudiando = false
    //   this.certificadoIngresadoAdjunto = null
    //   this.$refs.fechaInicioCertificado.reset()
    // },
    certificadoIngresadoValidacion() {
      let validez = false
      if (this.certificadoIngresado != null && this.certificadoIngresado != "") {
        validez = true
        // this.errorCertificado = []
      } else {
        validez = false
        // this.errorCertificado = ["Favor ingresar el certificado obtenido"]
      }
      return validez
    },
    institutoCertificadoValidacion() {
      let validez = false
      if (this.institucionIngresada != null && this.institucionIngresada != "") {
        validez = true
        // this.errorInstitutoCertificado = []
      } else {
        validez = false
        // this.errorInstitutoCertificado = ["Favor ingresar el nombre del instituto"]
      }
      return validez
    },
    fechasCertificacionValidacion() {

    },

    convertirFecha(inputDate) {
      const dateParts = inputDate.split('-');
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      const newDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      return newDate;
    },
    async getNivelEducativo() {
      const response = await this.services.datosPersona.getNivelEducativo({
        paginacion: false
      })
      this.catalogoNivelEducativo = response.data
    },
    async getFormacionEducativa(nivelEducativoSeleccionado) {
      const response = await this.services.datosPersona.getFormacionEducativa({
        id_nivel_educativo: nivelEducativoSeleccionado,
        paginacion: false
      })
      this.catalogoTituloObtenido = response.data
    },
    async showDocument(tipoConsulta, id) {

      const params = {
        tipo_consulta: tipoConsulta,
        tipo_documento: 1,
        id_persona: Number(id),
      }
      try {
        this.loading = true;
        let response = await this.services.datosPersona.getDocumentoPersona(params);
        if (response.status == 200) {


          //  this.getDoc(response?.data);

          this.showDialogDocumento = true;
          this.verDocuemnto = response?.data.archivo;
          this.typeDoc = response?.data.type;


        }
      } catch (e) {

      } finally {
        this.loading = false;
      }

    },
    closeDialogDocumento() {
      this.showDialogDocumento = false
      this.verDocuemnto = null;
      this.typeDoc = null;

    },
    async formacionSigoEstudiando(sigoEstudiando) {
      if (sigoEstudiando) {
        this.fechaFinalizacionFormacion = null;
        this.fechaFinalizacionFormacionFormatted = null;
      }
    },
    async formacionCertificadoSigoEstudiando(sigoEstudiando) {
      if (sigoEstudiando) {
        this.fechaFinalizacionCertificado = null;
        this.fechaFinalizacionCertificadoFormatted = null;
      }
    }
  },
  mounted(){this.getFechaActual()},
  computed: {
    ...mapState(["userInfo"]),
    nivelEducativoSeleccionadoErrors() {
      let errors = [];
      if (!this.$v.nivelEducativoSeleccionado?.$dirty) return errors
      !this.$v.nivelEducativoSeleccionado?.required && errors.push("Campo requerido")
      return errors;
    },
    tituloObtenidoSeleccionadoErrors() {
      let errors = [];
      if (!this.$v.tituloObtenidoSeleccionado?.$dirty) return errors
      !this.$v.tituloObtenidoSeleccionado?.required && errors.push("Campo requerido")
      return errors;
    },
    errorFechaFinFormacion() {
      let errors = [];
      if (!this.$v.fechaFinalizacionFormacion?.$dirty) return errors
      !this.$v.fechaFinalizacionFormacion?.required && errors.push("Campo requerido")
      return errors;
    },
    errorFechaInicioFormacion() {
      let errors = [];
      if (!this.$v.fechaInicioFormacion?.$dirty) return errors
      !this.$v.fechaInicioFormacion?.required && errors.push("Campo requerido")
      return errors;
    },
    nombreInstitucionIngresadoErrors() {
      let errors = [];
      if (!this.$v.nombreInstitucionIngresado?.$dirty) return errors
      !this.$v.nombreInstitucionIngresado?.required && errors.push("Campo requerido")
      !this.$v.nombreInstitucionIngresado.noEmojisOrSpecialChars && errors.push("Caracteres inválidos");
      return errors;
    },
    esNacionalErrors() {
      let errors = [];
      if (!this.$v.esNacional?.$dirty) return errors
      !this.$v.esNacional?.required && errors.push("Campo requerido")
      return errors;
    },
    tituloObtenidoAdjuntoErrors() {
      let errors = [];
      if (!this.$v.tituloObtenidoAdjunto?.$dirty) return errors
      !this.$v.tituloObtenidoAdjunto?.validFormat && errors.push("Formato de archivo inválido")
      return errors;
    },
    fechaInicioFormacionFormatted: {
      get() {
        if (this.fechaInicioFormacion){
         // Sumar un día a this.fechaInicioFormacion y asignarlo a this.fechaMinima
        this.fechaMinima = this.moment(this.fechaInicioFormacion).add(1, 'days').format("YYYY-MM-DD");
          return this.moment(this.fechaInicioFormacion).format("DD-MM-YYYY")
        }
      },
      set() {
        return
      }
    },
    fechaFinalizacionFormacionFormatted: {
      get() {
        if (this.fechaFinalizacionFormacion) return this.moment(this.fechaFinalizacionFormacion).format("DD-MM-YYYY")
      },
      set() {
        return
      }
    },
    fechaInicioCertificadoFormatted: {
      get() {
        if (this.fechaInicioCertificado) {
          // Sumar un día a this.fechaInicioFormacion y asignarlo a this.fechaMinima
          this.fechaMinimaC = this.moment(this.fechaInicioCertificado).add(1, 'days').format("YYYY-MM-DD");
          return this.moment(this.fechaInicioCertificado).format("DD-MM-YYYY")
        }
      },
      set() {
        return
      }
    },
    fechaFinalizacionCertificadoFormatted: {
      get() {
        if (this.fechaFinalizacionCertificado) return this.moment(this.fechaFinalizacionCertificado).format("DD-MM-YYYY")
      },
      set() {
        return
      }
    },

    errorFechaFinCertificado() {
      const errors = []
      if (this.fechaInicioCertificado > this.fechaFinalizacionCertificado) {
        errors.push("Favor elegir una fecha mayor o igual a la de inicio")
      }
      return errors
    },
    errorFechaInicioCertificado() {
      const errors = []
      if (this.fechaInicioCertificado > this.fechaFinalizacionCertificado) {
        errors.push("Favor elegir una fecha menor o igual a la de finalización")
      }
      return errors
    },
    errorCertificado() {
      const errors = []
      if (!this.$v.certificadoIngresado?.$dirty) return errors
      !this.$v.certificadoIngresado?.required && errors.push("Campo requerido")
      !this.$v.certificadoIngresado.noEmojisOrSpecialChars && errors.push("Caracteres inválidos");
      return errors
    },
    errorInstitutoCertificado() {
      const errors = []
      if (!this.$v.institucionIngresada?.$dirty) return errors
      !this.$v.institucionIngresada?.required && errors.push("Campo requerido")
      !this.$v.institucionIngresada.noEmojisOrSpecialChars && errors.push("Caracteres inválidos");
      return errors
    },
  },
  async created() {
    await this.getNivelEducativo()
    this.token = localStorage.getItem('token')
    if (this.token) {
      const userDecode = jwtDecode(this.token)
      this.dataPersona = userDecode.persona
    }
    await this.listarFormacionPersona()
  }
}
</script>

<style>
.ocultar-elemento {
  visibility: hidden;
  opacity: 0;
}

</style>
